/* eslint-disable no-unused-vars */ /* eslint-disable no-unused-vars */
<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('COMMON.SEARCH_OPTIONS')">
          <template v-slot:body>
            <b-row>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                  class="mb-0"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('LAST_LOGINS.START_DATE')"
                  class="mb-0"
                >
                  <b-form-datepicker
                    v-model="searchForm.startDate"
                    class="mb-2"
                    :max="searchForm.endDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group :label="$t('LAST_LOGINS.END_DATE')" class="mb-0">
                  <b-form-datepicker
                    v-model="searchForm.endDate"
                    class="mb-2"
                    :min="searchForm.startDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col class="my-sm-2">
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in userData"
                    v-bind:key="index"
                  >
                    <b-row>
                      <b-col md="4">
                        <b-form-select
                          v-model="item.label"
                          :options="typeData"
                          value-field="value"
                          text-field="text"
                          class="mr-sm-4"
                        ></b-form-select>
                      </b-col>
                      <b-col md="4" class="mt-2 mt-md-0">
                        <b-input
                          v-model="item.value"
                          id="inline-form-input-name"
                          :placeholder="$t('COMMON.VALUE')"
                        ></b-input>
                      </b-col>
                      <b-col
                        md="4"
                        class="d-flex justify-content-between align-items-center mt-2 mt-md-0"
                      >
                        <div>
                          <b-form-checkbox v-model="item.isnagte">{{
                            $t("LAST_LOGINS.NEGATE_CONDITION")
                          }}</b-form-checkbox>
                        </div>
                        <div>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="cyan"
                            @click="addRow()"
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="pink"
                            @click="deleteRow(index)"
                          >
                            <v-icon dark>mdi-minus</v-icon>
                          </v-btn>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col class="d-flex justify-content-end">
                <b-button variant="primary" class="mr-4" @click="searchData()">
                  {{ $t("COMMON.SEARCH") }}
                </b-button>
                <b-button variant="warning" @click="resetSearch()">
                  {{ $t("LAST_LOGINS.RESET_SEARCH") }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
        <KTPortlet v-bind:title="$t('COMMON.SEARCH_RESULTS')">
          <template v-slot:toolbar>
            <download-csv :data="incomingLogsTable.outgoingLogs">
              <b-button variant="success" class="mr-4">
                {{ $t("USERS.EXPORT_RESULTS") }}
              </b-button>
            </download-csv>
            <b-button
              variant="primary"
              class="mr-4"
              @click="toWBlist('whitelistAndDeliver')"
              v-if="canAdd"
              :disabled="incomingLogsTable.selected.length == 0"
            >
              {{ $t("LOGS.INCOMING_LOGS.WHITELIST_DELIVER") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              @click="toWBlist('blacklistAndDeliver')"
              v-if="canAdd"
              :disabled="incomingLogsTable.selected.length == 0"
            >
              {{ $t("MENU.BLACKLIST") }}
            </b-button>
          </template>
          <template v-slot:body>
            <v-data-table
              v-model="incomingLogsTable.selected"
              :headers="headers"
              :items="incomingLogsTable.outgoingLogs"
              :single-select="incomingLogsTable.singleSelect"
              :items-per-page="incomingLogsTable.itemPerPage"
              :server-items-length="incomingLogsTable.total"
              :options.sync="incomingLogsTable.pagination"
              @update:items-per-page="changePerPage"
              @update:page="searchData"
              show-select
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 25, 50, 1000],
              }"
            >
              <template v-slot:item.tostatus="{ item }">
                <span
                  class="btn btn-link"
                  @click="openStatusModal(item.tostatusMsg)"
                  >{{ item.tostatus }}</span
                >
              </template>
              <template v-slot:item.from="{ item }">
                <span v-b-tooltip.hover :title="item.hfrom">
                  {{ item.from }}
                </span>
              </template>
              <template v-slot:item.sentTo="{ item }">
                <span>
                  {{ item.sentTo }}
                  <span class="btn-link" @click="openSentToModal(item.to)">
                    {{ item.sentToMore }}
                  </span>
                </span>
              </template>
            </v-data-table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <!-- ----------- show status modal -->
    <b-modal :title="$t('COMMON.STATUS')" ref="showStatus-modal">
      <p class="my-1">
        {{ modalText }}
      </p>
      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="modalClose('showStatus-modal')">
          {{ $t("COMMON.CANCEL") }}
        </b-button>
      </template>
    </b-modal>
    <!-- ----------- show sent to modal -->
    <b-modal :title="$t('COMMON.STATUS')" ref="showSentTo-modal" size="lg">
      <template>
        <b-table striped hover :items="sentToDetailed" :fields="sentToDetailedHeaders"></b-table>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="modalClose('showSentTo-modal')">
          {{ $t("COMMON.CANCEL") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";

export default {
  name: "incominglogs",
  components: {
    KTPortlet,
  },
  data: () => ({
    incomingLogsTable: {
      singleSelect: false,
      outgoingLogs: [],
      selected: [],
      itemPerPage: 10,
      total: 0,
      pagination: {},
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    searchForm: {
      startDate: new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
    },
    userData: [{ label: "hfrom", value: "", isnagte: 0 }],
    modalText: "",
    sentToDetailed: [],
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("COMMON.DATE"),
          value: "timestamp",
          sortable: false,
        },
	{
          text: this.$t("COMMON.STATUS"),
          value: "tostatus",
          sortable: false,
	  align: 'center',
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.ENVELOPE_FROM"),
          value: "from",
          sortable: false,
          width: '200px',
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.TO"),
          value: "sentTo",
          sortable: false,
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.SUBJECT"),
          value: "subject",
          sortable: false,
          width: '200px',
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.IP"),
          value: "remoteip",
          sortable: false,
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.SPAM_SCORE"),
          value: "rspamdscore",
          sortable: false,
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.DNSBL"),
          value: "dnsbl",
          sortable: false,
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.SPF"),
          value: "spf",
          sortable: false,
        },
      ];
    },
    sentToDetailedHeaders: function () {
      return [
        {
          label: this.$t("LOGS.INCOMING_LOGS.TO"),
          key: "sentTo",
        },
        {
          label: this.$t("LOGS.INCOMING_LOGS.DELIVERED"),
          key: "tostatus",
        },
        {
          label: this.$t("LOGS.INCOMING_LOGS.DETAILS"),
          key: "tostatusMsg",
        },
      ];
    },
    typeData: function () {
      return [
        { text: this.$t("LOGS.INCOMING_LOGS.HEADER_FROM"), value: "hfrom" },
        { text: this.$t("LOGS.INCOMING_LOGS.ENVELOPE_FROM"), value: "from" },
        { text: this.$t("HOME.REMOTE_IP"), value: "remote_ip" },
        { text: this.$t("LOGS.INCOMING_LOGS.TO"), value: "to" },
        { text: this.$t("LOGS.INCOMING_LOGS.SUBJECT"), value: "subject" },
        { text: this.$t("LOGS.INCOMING_LOGS.DELIVERED"), value: "delivered" },
        {
          text: this.$t("LOGS.INCOMING_LOGS.UNDELIVERED"),
          value: "undelivered",
        },
        {
          text: this.$t("LOGS.INCOMING_LOGS.DELIVERED_TO"),
          value: "qarecipients",
        },
        { text: this.$t("LOGS.INCOMING_LOGS.REJECTED"), value: "rejected" },
      ];
    },
    canAdd: function () {
      return this.hasPermission("logs", 5);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
      includeAliasDomains: 1,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.INCOMING_LOGS") },
    ]);
  },
  methods: {
    /*  -----modal close------- */
    modalClose(modal) {
      this.$refs[modal].hide();
    },

    /* ----- dynamic row----- */
    addRow() {
      this.userData.push({
        label: "username",
        value: "",
        isnagte: 0,
      });
    },
    deleteRow(index) {
      if (this.userData.length != 1) this.userData.splice(index, 1);
    },
    searchData() {
      let userField = "";
      for (let i in this.userData) {
        let isnagteValue = 0;
        //    if (this.userData[i].value == "") {
        //      this.makeToastVariant("danger", "Please, fill in all fields");
        //      return;
        //    }
        if (this.userData[i].isnagte == true) {
          isnagteValue = 1;
        }
        let eachUserField =
          "&field[]=" +
          this.userData[i].label +
          "&search[]=" +
          this.userData[i].value +
          "&negate[]=" +
          isnagteValue;

        userField += eachUserField;
      }

      let fields =
        "domain=" +
        this.selectDomain.selectedDomain.domain +
        "&loga=" +
        this.searchForm.startDate +
        "&logz=" +
        this.searchForm.endDate +
        userField;

      const offset =
        (this.incomingLogsTable.pagination.page - 1) * this.incomingLogsTable.itemPerPage;
      const limit = this.incomingLogsTable.itemPerPage;
      this.incomingLogsTable.outgoingLogs = [];
      this.incomingLogsTable.selected = [];
      postRequest({
        action: "searchConnectionLogs",
        token: localStorage.id_token,
        fields: fields,
        offset: offset,
        limit: limit,
      }).then((res) => {
        if (res && res.returncode) {
          for (let k in res.returndata) {
            let ret = this.processStatus(res.returndata[k].to[0]);
            res.returndata[k].tostatus = ret.tostatus;
            res.returndata[k].tostatusMsg = ret.tostatusMsg;
            res.returndata[k].sentTo = ret.sentTo;
            res.returndata[k].sentToMore = "";
            if (res.returndata[k].to.length > 1) {
              res.returndata[k].sentToMore = this.$t(
                "LOGS.INCOMING_LOGS.MORE",
                { num: res.returndata[k].to.length - 1 }
              );
            }
          }
          this.incomingLogsTable.total = res.meta.all;
          this.incomingLogsTable.outgoingLogs = res.returndata;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    processStatus(to) {
      let ret = { tostatus: "", tostatusMsg: "", sentTo: "" };

      if (to.status.hasOwnProperty("rejected")) {
        ret.tostatus = this.$t("LOGS.INCOMING_LOGS.REJECTED");
        ret.tostatusMsg = to.status.rejected;
      } else if (to.status.hasOwnProperty("delivered")) {
        ret.tostatus = this.$t("LOGS.INCOMING_LOGS.DELIVERED");
        ret.tostatusMsg = to.status.delivered + " - " + to.status.msg;
      } else if (to.status.hasOwnProperty("deliveredspam")) {
        ret.tostatus = "Spam";
        ret.tostatusMsg = to.status.deliveredspam + " - " + to.status.msg;
      } else if (to.status.hasOwnProperty("whitelisted")) {
        ret.tostatus = this.$t("LOGS.INCOMING_LOGS.WHITELISTED");
        ret.tostatusMsg = to.status.whitelisted + " - " + to.status.msg;
      } else {
        ret.tostatus = this.$t("LOGS.INCOMING_LOGS.UNDELIVERED");
        ret.tostatusMsg = to.status.undelivered;
      }
      ret.sentTo = to.address;
      return ret;
    },
    resetSearch() {
      this.incomingLogsTable.outgoingLogs = [];
      this.incomingLogsTable.itemPerPage = 10;
      this.incomingLogsTable.pagination = {};
      this.incomingLogsTable.total = 0;
      this.searchForm.startDate = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10);
      this.searchForm.endDate = new Date().toISOString().slice(0, 10);
      this.userData = [{ label: "hfrom", value: "", isnagte: 0 }];
      this.selectDomain.selectedDomain = this.selectDomain.allDomains[0];
    },
    changePerPage(arg) {
      this.incomingLogsTable.itemPerPage = arg;
      this.searchData();
    },
    openStatusModal(modalData) {
      this.modalText = modalData;
      this.$refs["showStatus-modal"].show();
    },
    openSentToModal(to) {
      let results = [];
      for (let k in to) {
        results[k] = this.processStatus(to[k]);
      }
      this.sentToDetailed = results;
      this.$refs["showSentTo-modal"].show();
    },

    /* to whitelist and delivered */
    toWBlist(actionType) {
      let params = [];
      let domain = this.selectDomain.selectedDomain.domain;
      this.incomingLogsTable.selected.forEach((el, i) => {
        let index = el.index.substr(el.index.length - 10).replace(/-/g, "");
        let delivered = false;
        let deliveredto = "";

        let idField = "fields[" + i + "][id]";
        let fromField = "fields[" + i + "][from]";
        let remoteipField = "fields[" + i + "][remoteip]";
        let domainField = "fields[" + i + "][domain]";
        let deliveredField = "fields[" + i + "][delivered]";
        let indexField = "fields[" + i + "][index]";
        let toField = "fields[" + i + "][to]";
        let deliveredtoField = "fields[" + i + "][deliveredto]";

        if (el.to[0].status.delivered) {
          delivered = true;
          deliveredto = el.to[0].status.delivered;
        }

        let obj = {};
        obj[idField] = el.id;
        obj[fromField] = el.from;
        obj[remoteipField] = el.remoteip;
        obj[domainField] = domain;
        obj[deliveredField] = delivered;
        obj[indexField] = index;
        obj[toField] = el.to[0].address;
        obj[deliveredtoField] = deliveredto;
        Object.assign(params, obj);
      });

      postRequest({
        action: actionType,
        token: localStorage.id_token,
        ...params,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", this.$t("COMMON.SUCCESS_ALERT"));
        } else {
          this.makeToastVariant("danger", this.$t("COMMON.SUCCESS_ALERT"));
        }
        this.incomingLogsTable.selected = [];
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
